import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
// import styled from "styled-components"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/header"
import ImageAndCopySection from "../components/image-and-copy-section"

const Venue = ({
  // location,
  data: {
    contentfulVenue: {
      seoTitle,
      seoDescription,
      seoKeywords,
      image,
      venueName,
      venueGem,
      capacity,
      squareFootage,
      dimensions,
      extras,
      content,
    },
  },
}) => (
  <Layout>
    <SEO
      title={seoTitle}
      description={seoDescription}
      meta={[{ name: "keywords", content: seoKeywords.join(", ") }]}
    />
    <Header
      image={image}
      title={venueName}
      venueGem={venueGem}
      capacity={capacity}
      squareFootage={squareFootage}
      dimensions={dimensions}
      extras={extras}
      backLink={
        "/venues/"
        // location
        //   ? location.state
        //     ? location.state.referrer
        //     : undefined
        //   : undefined
      }
    />
    {content.map((contentItem, index) => (
      <ImageAndCopySection
        key={index}
        contentOnRight={(index + 1) % 2 === 0 ? true : false}
        image={contentItem.image}
        title={contentItem.title}
        text={contentItem.copy ? contentItem.copy.copy : null}
      />
    ))}
  </Layout>
)

export default Venue

Venue.propTypes = {
  location: PropTypes.object,
  data: PropTypes.shape({
    contentfulVenue: PropTypes.shape({
      seoTitle: PropTypes.string,
      seoDescription: PropTypes.string,
      seoKeywords: PropTypes.array,
      image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
          details: PropTypes.shape({
            image: PropTypes.shape({
              height: PropTypes.number,
              width: PropTypes.number,
            }),
          }),
        }),
        fluid: PropTypes.object,
      }),
      venueName: PropTypes.string,
      venueGem: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
          details: PropTypes.shape({
            image: PropTypes.shape({
              height: PropTypes.number,
              width: PropTypes.number,
            }),
          }),
        }),
        fluid: PropTypes.object,
      }),
      capacity: PropTypes.string,
      squareFootage: PropTypes.string,
      dimensions: PropTypes.string,
      extras: PropTypes.string,
      content: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.shape({
            description: PropTypes.string,
            file: PropTypes.shape({
              url: PropTypes.string,
              details: PropTypes.shape({
                image: PropTypes.shape({
                  height: PropTypes.number,
                  width: PropTypes.number,
                }),
              }),
            }),
            fluid: PropTypes.object,
          }),
          title: PropTypes.string,
          copy: PropTypes.shape({
            copy: PropTypes.string,
          }),
          linkAddress: PropTypes.string,
          linkText: PropTypes.string,
          invertedColours: PropTypes.bool,
        })
      ),
    }),
  }),
}

export const query = graphql`
  query VenueQuery($slug: String!) {
    contentfulVenue(slug: { eq: $slug }) {
      slug
      seoTitle
      seoDescription
      seoKeywords
      image {
        description
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
        fluid(maxWidth: 2400) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      venueName
      venueGem {
        description
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      capacity
      squareFootage
      dimensions
      extras
      content {
        image {
          description
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
          fluid(maxWidth: 1500) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        title
        copy {
          copy
        }
      }
    }
  }
`
